header {
    position: relative;
    .stoerer {
        position: absolute;
        width: 15vw;
        height: 15vw;
        min-width: 14rem;
        min-height: 14rem;
        border-radius: 50%;
        color: white;
        text-align: center;
        top: 10%;
        left: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2rem;
        transform-origin: center;
        transform: rotate(-10deg);
        @include tablet-only {
            min-width: 20rem;
            min-height: 20rem;
            font-size: $x3; }
        @include desktop {
            min-width: 22rem;
            min-height: 22rem;
            font-size: $x4; } } }
.produkte {
    display: flex;
    flex-wrap: wrap;
    .produkt {
        position: relative;
        background: white;
        border: 1px solid #cdcdcd;
        // box-shadow: 0 0 0.5rem rgba(black, 0.15)
        width: calc(100% / 4 - 2rem);
        // width: calc(100% / 3 - 1.3333rem)
        margin-right: 2rem;
        margin-bottom: 2rem;
        padding-bottom: 14rem;
        display: block;
        flex-direction: column;
        justify-content: space-between;

        .produkt--content {
            padding: 2rem;
            padding-bottom: 0; }
        .produkt--preis {
            padding: 2rem;
            padding-top: 0;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%; }

        @include desktop-only {
            width: calc(100% / 3 - 1.3333rem);
            &:nth-child(3n) {
                margin-right: 0; } }
        @include desktop-large {
            &:nth-child(4n) {
                margin-right: 0rem; } }

        @include tablet-only {
            width: calc(100% / 2 - 1rem);
            &:nth-child(2n) {
                margin-right: 0; } }
        @include mobile {
            width: 100%;
            margin-right: 0; }
        h2 {
            font-size: $x3;
            margin-bottom: 1rem;
            display: block;
            padding-bottom: 1rem;
            border-bottom: 1px solid #cdcdcd; }
        p {
            margin-bottom: 2rem; }
        .preis {
            display: block;
            text-align: center;
            color: lighten(black, 60%);
            font-weight: normal;
            margin: 0 0 2rem;
            font-size: $x6;
            padding: 1rem 0;
            small {
                font-size: 50%;
                margin-right: 1rem; } }
        button {
            width: 100%; } } }
button {
    appearance: none;
    background: $main;
    color: white;
    cursor: pointer;
    border: none;
    text-transform: uppercase;
    padding: 1rem 2rem;
    border-radius: 4rem;
    font-size: $x2;
    letter-spacing: 1px;
    transition: all 300ms ease;
    &:hover {
        opacity: 0.8; } }
#form {
    display: block;
    width: 100%;
    max-width: 80rem;
    margin: 0 auto;
    h3, h4 {
        font-size: $x5;
        margin-bottom: 2rem;
        text-transform: uppercase; }
    h3 {
        color: $main; }
    h4 {
        font-size: $x4;
        margin-top: 2rem;
        padding-top: 2rem; }

    input:not([type="checkbox"]), textarea {
        appearance: none;
        display: block;
        width: 100%;
        margin-bottom: 2rem;
        padding: 0.6rem;
        border: 1px solid #cdcdcd;
        border-radius: 0;
        box-shadow: none; }

    label {
        font-weight: bold;
        margin-bottom: 0.5rem;
        display: block; }
    .flex--inputs {
        display: flex;
        .input--group {
            flex: 1 0 45%;
            &.large {
                flex: 1 0 50%;
                margin-right: 2rem;
                input {
                    margin-bottom: 0; } }
            &.small {
                flex: 1 0 30%;
                input {
                    margin-bottom: 0; } }
            &.last {
                width: 4rem;
                max-width: 4rem; } } }
    #cart {
        border: 1px solid #cdcdcd;
        .flex--inputs {
            border-bottom: 1px solid #cdcdcd;
            padding: 2rem;
            // padding-bottom: 0
            @include mobile {
                padding: 1rem; }
            &:nth-child(odd) {
                background: #efefef; }
            .delete {
                width: 2.5rem;
                height: 2.5rem;
                margin-left: auto;
                font-size: $x3;
                border-radius: 50%;
                background: $main;
                color: white;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 0.6rem; } }
        .total {
            padding: 0 2rem;
            text-align: right;
            h4 {
                padding-top: 0;
                input {
                    display: inline;
                    padding: 0;
                    font: inherit;
                    width: auto;
                    margin: 0; } } } }

    .check--group {
        display: flex;
        align-items: flex-start;
        input {
            width: 2rem;
            margin-right: 1rem;
            position: relative;
            top: 5px; }
        label {
            flex: 1 0 80%; } }
    sup {
        font-size: 70%;
        color: $main;
        margin-left: 0.3rem; }
    button {
        margin: 2rem 0; }
    input:read-only {
        background: none;
        padding-left: 0;
        border: none;
        color: black; } }

#batterie {
    color: $main; }

.versand {
    padding: 1rem;
    color: white;
    text-align: center;
    margin-bottom: 3rem; }

.custom-color {
    color: $main; }
.custom-background {
    background: $main; }


.abweichende_adresse {
    display: none;
    padding: 2rem;
    background: #ececec;
    margin-bottom: 2rem;
    &.active {
        display: block; } }

#base {
    min-height: calc(100vh - 64px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;
    img {
        width: 80%;
        max-width: 400px; } }
