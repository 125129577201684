.modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(white, 0.95);
    display: flex;
    align-items: center;
    justify-content: center;
    .success, .error {
        padding: 2rem;
        margin-bottom: 3rem;
        h3 {
            font-size: $x4;
            margin-bottom: 1rem;
            opacity: 0.7; }
        .close {
            text-decoration: none;
            color: black;
            background: rgba(black, 0.7);
            color: white;
            padding: 0.7rem 2rem;
            border-radius: 5rem;
            display: inline-block;
            margin-top: 2rem; } }
    .success {
        border: 1px solid limegreen;
        background: rgba(limegreen, 0.3); }
    .error {
        border: 1px solid orangered;
        background: rgba(orangered, 0.3); } }

