nav {
    width: 100%;
    display: grid;
    grid-template-areas: 'text logo';
    grid-gap: 3rem;
    grid-template-columns: 1fr 30rem;
    padding: 2rem;
    align-items: center;
    .logo {
        display: flex;
        align-items: center;
        grid-area: text;
        img {
            max-width: 20rem;
            margin-right: 1rem; } }
    .logo-jonen {
        img {
            max-width: 27rem; } }
    .partnerlogo {
        grid-area: logo;
        max-width: 100%;
        justify-self: end;
        display: flex;
        align-items: center;
        img {
            max-width: 100%;
            height: auto;
            max-height: 7rem; } }
    .jonen-logo {
        display: block;
        height: 100%;
        img {
            display: block;
            margin-top: -2rem;
            margin-bottom: -2rem;
            max-height: 100px; } }
    @include tablet {
        grid-template-columns: 1fr 1fr;
        strong {
            font-size: $x3; }
        .logo {
            img {
                width: 13rem; } }
        .logo-jonen {
            img {
                max-width: 20rem;
                width: 100%; } } } }
