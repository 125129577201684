$main: #e9004e;

$x1: 1.2rem;
$x2: 1.6rem;
$x3: 2rem;
$x4: 2.4rem;
$x5: 3rem;
$x6: 4rem;

$mobile: 560px;
$tablet-start: 561px;
$tablet: 768px;
$desktop: 769px;
$desktop-large: 1140px;

@mixin mobile {
    @media (max-width: $mobile) {
        @content; } }

@mixin tablet {
    @media (max-width: $tablet) {
        @content; } }

@mixin tablet-only {
    @media (min-width: $tablet-start) and (max-width: $tablet) {
        @content; } }

@mixin desktop-only {
    @media (min-width: $desktop) and (max-width: $desktop-large) {
        @content; } }
@mixin desktop {
    @media (min-width: $desktop) {
        @content; } }
@mixin desktop-large {
    @media (min-width: $desktop-large) {
        @content; } }
