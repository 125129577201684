@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');

* {
    font-family: 'Roboto', sans-serif; }

p, a, strong, em, * {
    font-size: $x2; }
span {
    font-size: inherit; }
small {
    font-size: 1.4rem; }

h1 {
    text-transform: uppercase;
    font-size: $x6;
    margin-bottom: 2rem;
    line-height: 1.2;
    @include tablet {
        font-size: $x5; } }
