* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html, body {
  height: 100%;
  font-size: 10px;
  line-height: 1.4;
  transition: all 1s ease; }

.clearfix::after {
  content: "";
  display: block;
  clear: both;
  font-size: 0;
  height: 0;
  visibility: hidden; }

img {
  max-width: 100%;
  display: block; }
section {
  width: 100%;
  margin: 3rem auto; }
.content {
  padding: 4rem;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  @include mobile {
    padding: 2rem; }
  &-small {
    max-width: 80rem; } }
.red--color {
  color: $main; }
.grey {
  background: #efefef; }


.mobile {
  display: block;
  @include tablet-only {
    display: none; }
  @include desktop {
    display: none; } }
.desktop {
  display: none;
  @include desktop {
    display: block; } }
